import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

// Pure Swipe
import "pure-swipe";
// PrimeVue
import { registerPrimeVueComponents } from "./primevue";
// PrimeFlex
import "primeflex/primeflex.css";
// Base & Custom Components
import { registerBaseComponents } from "./components";
import Clip from "./components/base/Clip";
import PageLayout from "./layouts/PageLayout";
import PageHeader from "./layouts/PageHeader";
import Pending from "./layouts/Pending";
// import NavBar from "./components/NavBar";
import NavBar from "./layouts/NavBar";
import FooterNav from "./components/FooterNav";
import ActionsNav from "./components/ActionsNav";
// Directives
import registerDirectives from "./directives";

import { projectAuth } from "./firebase/config";
let app;
projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(router);
    registerBaseComponents(app);
    registerPrimeVueComponents(app);
    registerDirectives(app);
    // Custom components
    app.component("Clip", Clip);
    app.component("PageLayout", PageLayout);
    app.component("PageHeader", PageHeader);
    app.component("NavBar", NavBar);
    app.component("FooterNav", FooterNav);
    app.component("ActionsNav", ActionsNav);
    app.component("Pending", Pending);
    app.mount("#app");
  }
});
