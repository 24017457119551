import PrimeVue from "primevue/config";

// Services
import ConfirmationService from "primevue/confirmationservice";

// Stylesheets
import "primevue/resources/themes/saga-blue/theme.css";
// import "primevue/resources/themes/fluent-light/theme.css";
import "primevue/resources/primevue.css";
import "primeicons/primeicons.css";
import "../assets/_primevue.scss";

// Directives
import Ripple from "primevue/ripple";
import Tooltip from "primevue/tooltip";
import BadgeDirective from "primevue/badgedirective";

// Components
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Card from "primevue/card";
import ConfirmDialog from "primevue/confirmdialog";
import ProgressBar from "primevue/progressbar";
import Textarea from "primevue/textarea";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Chip from "primevue/chip";
import Menu from "primevue/menu";
import ProgressSpinner from "primevue/progressspinner";
import Skeleton from "primevue/skeleton";
import Sidebar from "primevue/sidebar";
import Steps from "primevue/steps";
import ToastService from "primevue/toastservice";
import Password from "primevue/password";
import Tree from "primevue/tree";
import InputNumber from "primevue/inputnumber";
import SpeedDial from "primevue/speeddial";
import Toast from "primevue/toast";
import Inplace from "primevue/inplace";
import Checkbox from "primevue/checkbox";
import ToggleButton from "primevue/togglebutton";
import ConfirmPopup from "primevue/confirmpopup";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Carousel from "primevue/carousel";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Badge from "primevue/badge";
import Divider from "primevue/divider";
import Timeline from "primevue/timeline";
import InputMask from "primevue/inputmask";
import Calendar from "primevue/calendar";
import TreeTable from "primevue/treetable";
import Tag from "primevue/tag";
import InputSwitch from "primevue/inputswitch";
import Panel from "primevue/panel";
import Listbox from "primevue/listbox";
import Chart from "primevue/chart";
import ContextMenu from "primevue/contextmenu";

export const registerPrimeVueComponents = function (app) {
  app.use(PrimeVue, { locale, ripple: true, inputStyle: "filled" });
  // Register services
  app.use(ConfirmationService);
  app.use(ToastService);
  // Register directives
  app.directive("ripple", Ripple);
  app.directive("tooltip", Tooltip);
  app.directive("badge", BadgeDirective);
  // Register components
  app.component("Dialog", Dialog);
  app.component("Toast", Toast);
  app.component("Button", Button);
  app.component("MultiSelect", MultiSelect);
  app.component("InputText", InputText);
  app.component("Dropdown", Dropdown);
  app.component("Card", Card);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("ProgressBar", ProgressBar);
  app.component("Textarea", Textarea);
  app.component("Avatar", Avatar);
  app.component("AvatarGroup", AvatarGroup);
  app.component("Chip", Chip);
  app.component("Menu", Menu);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("Skeleton", Skeleton);
  app.component("Sidebar", Sidebar);
  app.component("Steps", Steps);
  app.component("Password", Password);
  app.component("Tree", Tree);
  app.component("InputNumber", InputNumber);
  app.component("InputMask", InputMask);
  app.component("SpeedDial", SpeedDial);
  app.component("Inplace", Inplace);
  app.component("Checkbox", Checkbox);
  app.component("ToggleButton", ToggleButton);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("Carousel", Carousel);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("Badge", Badge);
  app.component("Divider", Divider);
  app.component("Timeline", Timeline);
  app.component("Calendar", Calendar);
  app.component("TreeTable", TreeTable);
  app.component("Tag", Tag);
  app.component("InputSwitch", InputSwitch);
  app.component("Panel", Panel);
  app.component("Listbox", Listbox);
  app.component("Chart", Chart);
  app.component("ContextMenu", ContextMenu);
};

const locale = {
  startsWith: "Commence par",
  contains: "Contient",
  notContains: "Ne contient pas",
  endsWith: "Termine par",
  equals: "Est",
  notEquals: "N'est pas",
  noFilter: "Aucun filtre",
  lt: "En dessous de",
  lte: "En dessous de ou égal à",
  gt: "Au dessus de",
  gte: "Au dessus de ou égal à",
  dateIs: "Date est",
  dateIsNot: "Date n'est pas",
  dateBefore: "Date est avant",
  dateAfter: "Date est après",
  clear: "Effacer",
  apply: "Appliquer",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Ajouter règle",
  removeRule: "Supprimer règle",
  accept: "Oui",
  reject: "Non",
  choose: "Choisir",
  upload: "Charger",
  cancel: "Anuler",
  dayNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Jui",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  today: "Aujourd'hui",
  weekHeader: "Sem",
  firstDayOfWeek: 0,
  dateFormat: "dd/mm/yy",
  weak: "Faible",
  medium: "Convenable",
  strong: "Fort",
  passwordPrompt: "Entrez un mot de passe",
  emptyFilterMessage: "Aucun résultat",
  emptyMessage: "Aucune option",
};
