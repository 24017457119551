import { ref } from "vue";
import { projectAuth } from "../firebase/config";

const user = ref(null);
const ROLES = require("./_roles.json");

const getUser = () => {
  if (projectAuth.currentUser) {
    projectAuth.currentUser.getIdTokenResult().then((idTokenResult) => {
      user.value = { ...projectAuth.currentUser, ...idTokenResult.claims };
      if (!user.value.role) {
        user.value.roles = ROLES[ROLES.length - 1];
      } else {
        user.value.roles = ROLES.slice(ROLES.indexOf(user.value.role));
      }
    });
  }
  return { user };
};

// const getUser = async () => {
//   const pending = ref(null);
//   const error = ref(null);
//
//   if (projectAuth.currentUser) {
//     pending.value = true;
//     const idTokenResult = await projectAuth.currentUser.getIdTokenResult();
//     user.value = { ...projectAuth.currentUser, ...idTokenResult.claims };
//     pending.value = false;
//   }
//
//   return { user, error, pending };
// };

// projectAuth.onAuthStateChanged((_user) => {
//   if (_user) {
//     _user.getIdTokenResult().then((idTokenResult) => {
//       user.value = { ..._user, ...idTokenResult.claims };
//       if (!user.value.role) {
//         user.value.roles = ["employee"];
//       } else {
//         user.value.roles = ROLES.slice(ROLES.indexOf(user.value.role));
//       }
//     });
//   }
// });

export { getUser };
