<template>
  <div class="base-select">
    <label v-if="label">{{ label }}</label>
    <div class="select">
      <fa-icon icon="caret-right" class="select-caret"></fa-icon>
      <select @change="$emit('update:modelValue', $event.target.value)">
        <option selected disabled>{{ placeholder }}</option>
        <slot></slot>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Choisissez...",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.base-select {
  width: 100%;
  position: relative;
  margin: 0.5rem 0;
}
label {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  color: $primary-dark;
}
.select {
  position: relative;
  min-width: 100%;
  height: 32px;
  border: solid 1px transparent;
  outline: none;
  border-radius: 0.25rem;
  background-color: rgba($primary, 0.05);
  color: inherit;
  margin-top: 0.25rem;
  .select-caret {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 2;
  }
  select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    border: solid 1px transparent;
    border-radius: 0.25rem;
    outline: none;
    background-color: transparent;
    color: $primary-dark;
    padding: 0 0.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 300ms ease;
    &:focus {
      border-color: $primary-dark;
    }
  }
}
</style>
