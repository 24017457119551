<template>
  <transition name="fade">
    <aside
      class="clip"
      v-if="modelValue"
      @click.self="$emit('update:modelValue', false)"
      id="clip"
    >
      <slot></slot>
    </aside>
  </transition>
</template>

<script>
export default {
  props: {
    modelValue: [Object, Array, Boolean],
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.clip {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(#000, 0.7);
  backdrop-filter: blur(2px);
}
</style>
