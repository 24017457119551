<template>
  <div class="modal-clip" @click.self="$emit('close')">
    <BaseCard>
      <template #header v-if="$slots.title"><slot name="title" /></template>
      <template #default><slot name="default" /></template>
      <template #footer v-if="$slots.actions"><slot name="actions" /></template>
    </BaseCard>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-clip {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000000, 0.7);
  backdrop-filter: blur(3px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
