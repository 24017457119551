import { projectDatabase } from "@/firebase/config";
import { computed, ref } from "vue";

const DEFAULT_MERCURIAL = "mercurial";
const EASILYS_MERCURIAL = "mercurial-easilys";

const mercurialFolder = ref(DEFAULT_MERCURIAL);

const setMercurial = async (centerId) => {
  const { easilysSiteId } = (
    await projectDatabase.ref(`centers/${centerId}`).get()
  ).val();

  if (!easilysSiteId) {
    mercurialFolder.value = DEFAULT_MERCURIAL;
    return;
  }
  mercurialFolder.value = EASILYS_MERCURIAL;
};

export default function useMercurial() {
  return {
    mercurialFolder: computed(() => mercurialFolder.value),
    setMercurial,
  };
}
