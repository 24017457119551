<template>
  <i
    class="mr-2"
    :class="[`fa fa-${status.icon}`, `text-${status.severity}`]"
  ></i>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    data: {
      type: [String, Number],
      default: () => undefined,
    },
  },
  setup(props) {
    const status = computed(() => {
      if (props.data > 0) return { severity: "success", icon: "circle-check" };
      if (props.data === 0) return { severity: "warning", icon: "ban" };
      return { severity: "muted", icon: "circle-xmark" };
    });
    return { status };
  },
};
</script>

<style lang="scss" scoped></style>
