<template>
  <div class="input-group">
    <label>
      <template v-if="!error">
        <slot></slot>
      </template>
      <template v-else>
        <span class="error">{{ error }}</span>
      </template>
    </label>
    <input
      :type="showPassword ? 'text' : 'password'"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      :class="error ? 'error' : ''"
      :required="required"
      :disabled="disabled"
    />
    <fa-icon
      class="icon"
      :icon="['far', 'eye-slash']"
      @click="showPassword = true"
      v-if="!showPassword"
    ></fa-icon>
    <fa-icon
      class="icon"
      :icon="['far', 'eye']"
      @click="showPassword = false"
      v-else
    ></fa-icon>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    placeholder: String,
    error: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const showPassword = ref(false);

    return { showPassword };
  },
};
</script>

<style lang="scss" src="../../assets/_form.scss"></style>
