<template>
  <About></About>
  <Toast
    position="top-center"
    style="font-size: 0.75rem; max-width: 90vw; z-index: 9999"
  />
  <ConfirmDialog
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
  />
  <!--  <ConfirmPopup />-->
  <router-view />
</template>

<script>
import { onMounted } from "vue";
import About from "@/views/setup/About.vue";

export default {
  components: { About },
  setup() {
    onMounted(() => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
      document.documentElement.classList.add("p-input-filled");
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./assets/_styles.scss";

#app {
  min-height: calc(calc(var(--vh, 1vh) * 100));
  .container {
    max-width: 1024px;
    margin: 0 auto;
  }
  a {
    font-size: 0.75rem;
    color: $primary;
    text-decoration: none;
    transition: all 300ms;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .jumbo {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 2rem;
    span:first-child {
      font-weight: 100;
      font-size: 1.75rem;
      padding: 0.5rem 0;
    }
  }
  .text-center {
    text-align: center;
  }
  .text-sm {
    font-size: 0.75rem;
  }
  .row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.25rem;
    justify-content: space-between;
    align-items: center;
  }
  //fieldset {
  //  outline: none;
  //  box-shadow: none;
  //  border: solid 1px #f0f0f0;
  //  border-radius: 0.25rem;
  //  display: block;
  //  position: relative;
  //  font-size: 0.8rem;
  //  padding: 0.25rem;
  //  background-color: #f0f0f0;
  //  legend {
  //    font-size: 0.75rem;
  //  }
  //  &.infos {
  //    background-color: #f0f0f0;
  //  }
  //  p {
  //    padding: 0.25rem;
  //    margin: 0;
  //  }
  //  i {
  //    font-size: 1rem;
  //    position: absolute;
  //    right: 0.5rem;
  //    bottom: 10px;
  //    //bottom: 50%;
  //    transition: all 250ms;
  //  }
  //}
}
</style>
