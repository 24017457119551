<template>
  <li class="row-card">
    <Button
      icon="fa fa-bars"
      class="p-button-text p-button-secondary"
      :class="!user.roles?.moderator ? 'handle' : ''"
    ></Button>
    <div class="name" @click="$emit('on-click')"><slot /></div>
    <Button
      icon="fa fa-trash"
      class="p-button-text p-button-secondary"
      @click="$emit('on-remove')"
      v-if="!user.roles?.moderator"
    ></Button>
  </li>
</template>

<script>
import { getUser } from "../../composables/getUser";

export default {
  props: ["item"],
  setup() {
    const { user } = getUser();

    return { user };
  },
};
</script>

<style lang="scss" scoped>
.row-card {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.25rem;
  padding: 0.25rem;
  //padding: 0.5rem 1rem;
  border-bottom: solid 1px rgba($secondary-light, 0.1);
  align-items: center;
  position: relative;
  .name {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &:last-child {
    border: none;
  }
  &.sortable-chosen {
    background-color: $primary;
    color: white;
  }
}
</style>
