import { ref, watchEffect } from "vue";
import { projectDatabase } from "@/firebase/config";
import { objectSub, arraySub } from "./_subscribers";

export default (path) => {
  const getOne = (id) => {
    const doc = ref(null);
    const error = ref(null);
    const pending = ref(true);

    const dbRef = projectDatabase.ref(path + "/" + id);
    const sub = objectSub(dbRef, { data: doc, error, pending });
    dbRef.on("value", sub);
    watchEffect((onInvalidate) => {
      onInvalidate(() => dbRef.off("value", sub));
    });

    return { doc, error, pending };
  };

  const getAll = (opt = { limit: 1000, order: "position" }) => {
    const docs = ref([]);
    const error = ref(null);
    const pending = ref(true);
    const _count = ref(0);

    const dbRef = projectDatabase.ref(path).limitToFirst(opt.limit);
    const sub = arraySub(dbRef, { data: docs, error, pending, _count });
    dbRef.on("value", sub);
    watchEffect((onInvalidate) => {
      onInvalidate(() => dbRef.off("value", sub));
    });

    return { docs, error, pending, _count };
  };

  const mapAll = (opt = { limit: 5000 }) => {
    const docs = ref();
    const docsArray = ref([]);
    const error = ref(null);
    const pending = ref(true);
    const _count = ref(0);

    const dbRef = projectDatabase.ref(path).limitToFirst(opt.limit);
    const sub = () => {
      dbRef.on("value", (snapshot) => {
        if (snapshot.exists()) {
          docs.value = snapshot.val();
          docsArray.value = [];
          Object.entries(snapshot.val()).forEach((doc) => {
            docsArray.value.push({ id: doc[0], ...doc[1] });
          });
          error.value = null;
          _count.value = snapshot.numChildren();
        } else {
          error.value = { message: "Aucune entrée" };
        }
        pending.value = false;
      });
    };
    dbRef.on("value", sub);
    watchEffect((onInvalidate) => {
      onInvalidate(() => dbRef.off("value", sub));
    });

    return { docs, docsArray, error, pending, _count };
  };

  const addOne = async (newDoc) => {
    const doc = ref(null);
    const error = ref(null);
    const pending = ref(true);

    const dbRef = projectDatabase.ref(path);
    try {
      // const id = dbRef.push(newDoc).key;
      // await dbRef.push(newDoc);
      const resRef = await dbRef.push(newDoc);
      await resRef.get().then((snapshot) => {
        doc.value = { id: snapshot.key, ...snapshot.val() };
      });
      error.value = null;
    } catch (e) {
      error.value = { message: e.message };
      console.log(e);
    } finally {
      pending.value = false;
    }

    return { doc, error, pending };
  };

  const removeOne = async (id) => {
    const error = ref(null);
    const pending = ref(true);

    const dbRef = projectDatabase.ref(path + "/" + id);
    try {
      await dbRef.remove();
      error.value = null;
    } catch (e) {
      error.value = { message: e.message };
      console.log(e);
    } finally {
      pending.value = false;
    }

    return { error, pending };
  };

  const updateOne = async (id, data) => {
    const error = ref(null);
    const pending = ref(true);

    const dbRef = projectDatabase.ref(path + "/" + id);
    try {
      await dbRef.update(data);
      error.value = null;
    } catch (e) {
      error.value = { message: e.message };
    } finally {
      pending.value = false;
    }

    return { error, pending };
  };

  return { getOne, getAll, mapAll, addOne, removeOne, updateOne };
};
