<template>
  <div class="tag">
    <slot>{{ value }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: () => "",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
$opacity: 0.8;
$color: rgba(#607d8b, $opacity);
.tag {
  font-size: 0.6rem;
  padding: 0.3rem;
  border-radius: 0.2rem;
  letter-spacing: 1px;
  border: solid 1px $color;
  color: $color;
}
</style>
