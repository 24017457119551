<template>
  <BaseButtonSet
    label="A propos"
    icon="fa fa-info"
    color="secondary"
    @click="toggle = true"
    v-if="showBtn"
  />
  <Dialog
    modal
    :draggable="false"
    v-model:visible="toggle"
    closable
    header="A propos"
  >
    <template #default>
      <div class="flex align-items-center">
        <h4 class="mr-2">App Inventaire</h4>
        <small>v{{ version }}</small>
      </div>
      <div class="flex align-items-center mt-1">
        <small class="mr-2 text-xs">Propulsé par</small>
        <small class="font-italic">{{ author }}</small>
      </div>
    </template>
    <template #footer></template>
  </Dialog>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { version, author } from "../../../package.json";

export default {
  name: "AboutModal",
  props: ["showBtn"],
  setup(props) {
    const toggle = ref(false);

    onBeforeMount(() => {
      if (!props.showBtn) {
        window.addEventListener("keydown", (e) => {
          if (e.code === "Backquote" && e.ctrlKey) {
            toggle.value = true;
          }
        });
      }
    });

    return { toggle, version, author };
  },
};
</script>

<style lang="scss" scoped></style>
