<template>
  <div class="wrapper">
    <label for="btn" v-if="rtl" class="rtl">{{ label }}</label>
    <Button
      id="btn"
      type="button"
      class="p-button-rounded p-button-secondary"
      :icon="icon"
      style="max-width: 34px; max-height: 34px"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      @click="toggle"
    ></Button>
    <label for="btn" v-if="!rtl">{{ label }}</label>
  </div>
  <Menu id="overlay_menu" ref="menu" :model="items" :popup="true"></Menu>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    label: {
      type: String,
      default: () => "",
    },
    icon: {
      type: String,
      default: () => "fa fa-cog",
    },
    rtl: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const menu = ref();
    const toggle = (event) => {
      menu.value.toggle(event);
    };
    return {
      menu,
      toggle,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  label {
    margin-left: 0.5rem;
  }
  label.rtl {
    margin-right: 0.5rem;
  }
}
</style>
