<template>
  <button
    :class="[
      large ? 'large' : '',
      small ? 'small' : '',
      block ? 'block' : '',
      square ? 'square' : '',
      fill ? 'fill' : '',
      pending ? 'pending' : '',
      transparent ? 'transparent' : '',
      pending ? 'pending' : '',
    ]"
    :style="`background-color: ${colors[color]}`"
    :disabled="disabled"
  >
    <template v-if="pending">Connexion en cours...</template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<script>
import colors from "../../assets/_colors.scss";

export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    pending: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  setup() {
    return { colors };
  },
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  outline: none;
  border-radius: 0.25rem;
  background-color: $primary;
  color: white;
  font-size: 0.85rem;
  height: 32px;
  cursor: pointer;
  transition: all 300ms ease;
  &:disabled {
    background-color: #f0f0f0 !important;
    color: #cccccc;
    pointer-events: none;
  }
  &.pending {
    pointer-events: none;
  }
  &.square {
    border-radius: 0;
  }
  &.block {
    display: block;
    width: 100%;
  }
  &.large {
    min-height: 48px;
    font-size: 1.25rem;
  }
  &.small {
    max-height: 24px;
    font-size: 1rem;
  }
  &.transparent {
    background-color: transparent !important;
    color: inherit !important;
  }
  &.fill {
    //background-color: transparent !important;
    color: $primary !important;
    position: relative;
    &::before {
      content: "";
      height: 4px;
      background-color: $primary;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
