<template>
  <fieldset class="base-not-found">
    <div>{{ message }}</div>
    <div v-if="detail" class="mt-2">
      Commencez par <a @click.self="$emit('action')">{{ detail }}</a>
    </div>
  </fieldset>
</template>

<script>
import { getUser } from "../../composables/getUser";

export default {
  props: {
    message: String,
    detail: String,
  },
  setup() {
    const { user } = getUser();

    return { user };
  },
};
</script>

<style lang="scss" scoped>
.base-not-found {
  color: rgba($secondary, 0.5);
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-style: italic;
  text-align: center;
}
</style>
