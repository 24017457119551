<!--<style lang="scss" scoped>-->
<!--.base-checkbox {-->
<!--  display: flex;-->
<!--  justify-content: flex-start;-->
<!--  align-items: center;-->
<!--  cursor: pointer;-->
<!--  &.left {-->
<!--    justify-content: flex-end;-->
<!--  }-->
<!--  &__box {-->
<!--    width: 1.5rem;-->
<!--    height: 1.5rem;-->
<!--    background-color: #f0f0f0;-->
<!--    border-radius: 0.5rem;-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    align-items: center;-->
<!--    margin-right: 0.5rem;-->
<!--    color: $primary;-->
<!--  }-->
<!--  &.disabled {-->
<!--    color: rgba(#333333, 0.5);-->
<!--    .base-checkbox__box {-->
<!--      color: inherit;-->
<!--    }-->
<!--  }-->
<!--  &__label {-->
<!--    font-size: 0.75rem;-->
<!--  }-->
<!--}-->
<!--</style>-->

<template>
  <!--  <div class="base-checkbox" @click="checked = !checked">-->
  <!--    <div class="base-checkbox__box">-->
  <!--      <fa-icon icon="check" v-show="checked"></fa-icon>-->
  <!--    </div>-->
  <!--    <span class="base-checkbox__label" v-if="$slots.default"-->
  <!--      ><slot></slot-->
  <!--    ></span>-->
  <!--  </div>-->
  <div class="checkbox">
    <fa-icon icon="check" v-show="checked" class="icon"></fa-icon>
    <input type="checkbox" :id="uid" :checked="checked" @change="onChange" />
    <label :for="uid"><slot /></label>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";

export default {
  name: "BaseCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const checked = ref(props.modelValue);

    const onChange = (e) => {
      const val = e.target.checked;
      emit("update:modelValue", val);
      checked.value = val;
    };

    return { uid: getCurrentInstance().uid, checked, onChange };
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  .icon {
    position: absolute;
    left: 0.45rem;
    color: $primary;
  }
  label {
    font-size: 0.75rem;
  }
}
input[type="checkbox"] {
  appearance: none;
  display: flex;
  &::before {
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    background-color: #f0f0f0;
    border-radius: 0.25rem;
    display: block;
  }
}
</style>
