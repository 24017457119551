<template>
  <div class="actions-nav" :style="`bottom: ${padding}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      type: [String, Number],
      default: "0",
    },
    rtl: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-nav {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100vw;
  padding: 0 0.75rem;
}
</style>
