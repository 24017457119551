<template>
  <div class="pending">
    <ProgressSpinner></ProgressSpinner>
    <span class="pending-text"><slot /></span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.pending {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-text {
    font-size: 0.75rem;
    color: $secondary-light;
    margin-top: 1rem;
  }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: $primary;
  }
  33% {
    stroke: $primary-dark;
  }
  66% {
    stroke: $primary-light;
  }
  80%,
  90% {
    stroke: $primary;
  }
}
</style>
