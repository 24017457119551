<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :to="to"
    class="base-btn-set"
    :style="rtl ? 'flex-direction: row-reverse' : ''"
  >
    <!--  <div class="base-btn-set" :style="rtl ? 'flex-direction: row-reverse' : ''">-->
    <label>
      <span v-if="!$slots.default">{{ label }}</span>
      <slot v-else></slot>
    </label>
    <Button
      :class="[
        `p-button-${color}`,
        `${rounded ? 'p-button-rounded' : ''}`,
        `${text ? 'p-button-text' : ''}`,
        `${outlined ? 'p-button-outlined' : ''}`,
      ]"
      :icon="icon"
      :disabled="disabled"
    />
  </component>
  <!--  </div>-->
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    color: String,
    text: Boolean,
    rtl: Boolean,
    disabled: Boolean,
    outlined: Boolean,
    rounded: {
      type: Boolean,
      default: true,
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.base-btn-set {
  display: flex;
  align-items: center;
  label {
    font-size: 0.75rem;
    margin: 0 0.25rem;
    color: $secondary !important;
  }
  button {
    transform: scale(0.9);
  }
}
</style>
