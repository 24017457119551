<template>
  <nav class="footer-nav container">
    <router-link :to="{ name: 'Boards' }">
      <fa-icon icon="clipboard-list"></fa-icon>
    </router-link>
    <router-link :to="{ name: 'Zones' }">
      <fa-icon icon="archive"></fa-icon>
    </router-link>
    <router-link :to="{ name: 'Notifications' }">
      <fa-icon icon="bell"></fa-icon>
    </router-link>
    <router-link :to="{ name: 'Profile' }">
      <fa-icon icon="user"></fa-icon>
    </router-link>
    <router-link to="/">
      <fa-icon icon="cog"></fa-icon>
    </router-link>
  </nav>
</template>

<script>
import useLogout from "@/composables/useLogout";

export default {
  name: "FooterNav",
  setup() {
    const { logout } = useLogout();

    return { logout };
  },
};
</script>

<style lang="scss" scoped>
.footer-nav {
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.25rem;
  height: 100%;
  color: #cccccc;
  z-index: 1;
  a {
    color: inherit !important;
    font-size: inherit !important;
    &.router-link-active {
      color: $primary !important;
      transform: scale(1.25);
    }
  }
}
</style>
