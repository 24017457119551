<template>
  <div class="card-wrapper" :style="noWrapper ? 'padding: 0' : ''">
    <div
      class="card p-ripple secondary"
      v-ripple
      :class="[link ? 'link' : '', flex ? 'flex' : '']"
      :style="`height: ${height}`"
    >
      <div class="card-header" v-if="$slots.header">
        <slot name="header"></slot>
      </div>
      <div class="card-content" :style="noPadding ? 'padding: 0' : ''">
        <slot name="default"></slot>
      </div>
      <div class="card-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noWrapper: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "auto",
    },
    flex: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  padding: 1rem 1rem 0 1rem;
  position: relative;
  &:last-child {
    padding: 1rem;
  }
}
.card {
  overflow: hidden;
  border-radius: 0.25rem;
  background-color: #ffffff;
  //padding: 1rem;
  //display: block;
  display: block;
  box-shadow: 0 0.5rem 1rem rgba(#000000, 0.1);
  transition: all 300ms ease;
  &.flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.link {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(#000000, 0.1);
      transform: scale(1.005);
    }
  }
  .card-header {
    border-bottom: solid 1px #f0f0f0;
    padding: 1rem;
  }
  .card-content {
    padding: 1rem;
    overflow: hidden;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
