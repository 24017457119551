<template>
  <form @submit.prevent="toggleEdit">
    <div class="p-inputgroup">
      <InputText
        v-model="inplace"
        class="inplace"
        :disabled="!edit"
        id="input"
        @click.stop="() => null"
      />
      <Button
        :class="
          edit ? ['p-button-success'] : ['p-button-secondary', 'p-button-text']
        "
        :icon="edit ? 'fa fa-check' : 'fa fa-pen-alt'"
        type="submit"
        @click.stop="() => null"
      />
      <slot />
    </div>
  </form>
</template>

<script>
import { ref, nextTick, watch } from "vue";

export default {
  props: ["modelValue"],
  emits: ["edit", "onEdit"],
  setup(props, { emit }) {
    const inplace = ref(props.modelValue);
    const edit = ref(false);

    const toggleEdit = () => {
      if (!edit.value) {
        edit.value = true;
        nextTick().then(() => {
          document.querySelector("#input").focus();
        });
      } else {
        edit.value = false;
        if (props.modelValue !== inplace.value) {
          emit("edit", inplace.value);
          console.log(inplace.value);
        }
      }
    };

    watch(edit, () => {
      emit("onEdit", edit.value);
    });

    return { inplace, edit, toggleEdit };
  },
};
</script>

<style lang="scss" scoped>
.inplace:disabled {
  pointer-events: none;
}
</style>
